import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from './ui/dialog';
import { Button } from './ui/button';

export default function UpgradeTrialDialog({
  setOpen,
  open,
  onClick
}: {
  setOpen: any;
  open: boolean;
  onClick?: any;
}) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Your trial has run out!</DialogTitle>
          <DialogDescription>
            Don't worry your messages are still being served. To reactivate your
            dashboard, let's chat!
            <div className="mt-5 flex items-center justify-center gap-4">
              <Button
                className="bg-primary hover:bg-opacity-50"
                onClick={() => {
                  window.location.href =
                    'https://calendly.com/d/ck6t-2q7-tsf/meet-mendable';
                }}
              >
                Talk to us!
              </Button>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
