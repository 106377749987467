import Link from 'next/link';
import s from './Navbar.module.css';
import { Menu } from '@headlessui/react';
import Logo from 'components/icons/Logo';
import { useRouter } from 'next/router';
import { useUser } from 'utils/useUser';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { useScroll } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import NavigationBar from './NavigationBar';
import { cn } from '@/lib/utils';

const Navbar = () => {
  const router = useRouter();
  const supabaseClient = useSupabaseClient();
  const { user } = useUser();
  const { scrollYProgress } = useScroll();
  const [hookedYPostion, setHookedYPosition] = React.useState(0);

  const [isOpen, setIsOpen] = React.useState(false);
  React.useEffect(() => {
    // hook into the onChange, store the current value as state.
    scrollYProgress.onChange((v) => setHookedYPosition(v));
  }, [scrollYProgress]); //make sure to re-subscriobe when scrollYProgress changes

  const [menuItems, setMenuItems] = useState([
    { name: 'Documentation', link: '/usecases/documentation' },
    { name: 'Customer Support', link: '/usecases/customersupport' },
    { name: 'Product Copilot', link: '/usecases/productcopilot' },
    { name: 'Sales Enablement', link: '/usecases/salescopilot' }
  ]);

  // Function to shuffle array
  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  useEffect(() => {
    shuffleArray(menuItems);
    setMenuItems([...menuItems]);
  }, []);

  const [isOpen2, setIsOpen2] = React.useState(false);

  return (
    <nav
      style={{
        backgroundColor: hookedYPostion > 0.005 ? '#000' : 'transparent'
      }}
      className={s.root}
    >
      <a href="#skip" className="sr-only focus:not-sr-only">
        Skip to content
      </a>
      <div className="relative bg-white/10 backdrop-blur-sm px-4 py-2 text-white">
        <p className="text-center text-xs mx-auto max-w-sm sm:max-w-full sm:text-sm font-medium">
          Introducing {' '}
          <a
            href="https://firecrawl.dev/?ref=mendable+banner"
            className="inline-block underline"
            rel="noopener noreferrer"
            target="_blank"
          >
            Firecrawl 
            {' '}
          </a>
          🔥 - Turn entire websites into LLM-ready markdown or structured data
        </p>
      </div>
      <div className="relative mx-auto max-w-full lg:max-w-[1200px] px-6">
        {hookedYPostion > 0.005 && (
          <div className="absolute -bottom-px left-11 right-20 h-px bg-gradient-to-r from-blue-400/0 via-mendable-500 to-blue-400/0" />
        )}
        <div className="flex justify-between items-center align-center flex-row py-2 md:py-4 relative">
          <div className="flex items-center">
            <Link href="/">
              <a className={s.logo + ' flex items-center'} aria-label="Logo">
                <Logo className="w-[32px] h-[32px] mr-2" />
                <span className="ml-1 text-lg font-light">Mendable</span>
              </a>
            </Link>
          </div>
          <nav className="hidden md:block">
            <Menu as="div" className="relative inline-block text-left">
              <NavigationBar />
            </Menu>
          </nav>

          <div className="flex items-center">
            <div className="md:hidden block">
              <div className="-mr-2 flex md:hidden">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-gray-900 z-10 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            <Transition
              className="absolute top-12 bg-gray-900 rounded-md right-4"
              show={isOpen}
              enter="transition ease-out duration-100 transform"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75 transform"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {(ref) => (
                <div className="md:hidden" id="mobile-menu">
                  <div
                    ref={ref}
                    className="px-2 pt-2 pb-3 space-y-1 md:px-3 flex flex-col"
                  >
                    {/* <Link href="/features">
                      <a className={s.link}>Features</a>
                    </Link> */}
                    {/* <Link href="/try-it-out">
                <a className={s.link}>Try it out</a>
              </Link>
                    <Link href="/pricing">
                      <a className={s.link}>Pricing</a>
                    </Link>
                    <Link href="/how-it-works">
                      <a className={s.link}>How to use</a>
                    </Link>
                */}
                    {/* {user ? <Link href="/account">
                      <a className={s.link}>Account</a>
                    </Link> : null}  */}
                    {user ? (
                      <Link href="/router">
                        <a className={s.link}>Dashboard</a>
                      </Link>
                    ) : null}
                    {user ? (
                      <span
                        className={s.link}
                        onClick={async () => {
                          await supabaseClient.auth.signOut();
                          router.push('/signin');
                        }}
                      >
                        Sign out
                      </span>
                    ) : (
                      <Link href="/signin">
                        <a className={s.link}>Sign In</a>
                      </Link>
                    )}
                    <Link href="/#usecases">
                      <a className={s.link}>Use Cases</a>
                    </Link>
                    <Link href="https://docs.mendable.ai/">
                      <a className={s.link}>Docs</a>
                    </Link>
                    <Link href="/pricing">
                      <a className={s.link}>Pricing</a>
                    </Link>
                    <Link href="/blog">
                      <a className={s.link}>Blog</a>
                    </Link>
                  </div>
                </div>
              )}
            </Transition>
            <div className="hidden md:flex items-center text-sm">
              {/* <Link href="https://docs.mendable.ai/">
                <a className={s.link}>Documentation</a>
              </Link> */}
              {user ? (
                <Link href="/router">
                  <a className={s.link}>Dashboard</a>
                </Link>
              ) : null}
              {user ? (
                <span
                  className={s.link}
                  onClick={async () => {
                    await supabaseClient.auth.signOut();
                    router.push('/signin');
                  }}
                >
                  Sign out
                </span>
              ) : (
                <div className="gap-8 space-x-4">
                  <Link href="/signin">
                    <a className={cn(s.link, '!text-[15px]')}>Sign In</a>
                  </Link>
                  <Link href="/signup">
                    <a className={`btn  !text-[15px] text-white ${hookedYPostion <= 0.01 ? 'bg-gray-800/50' : 'bg-primary'} hover:bg-gray-800/75 border border-primary transition duration-150 ease-in-out group`}>
                      Get Started
                    </a>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
